import React from 'react';
import { Form } from 'components';

const App = () => (
  <div className="App">
    <Form/>
  </div>
);

export default App;
